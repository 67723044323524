import { type FC, useContext } from 'react';

import classNames from 'classnames';

import AccountContext from '@/AccountContext';

import { hexToRgb } from '../shared/utilities';

interface Props {
  disabled: boolean;
  day: number;
  status: string;
  handleClick: (event: React.MouseEvent) => void;
  selected: boolean;
  today: boolean;
}

function disabledColor(hexColor: string): string {
  const rgb = hexToRgb(hexColor);
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`;
}

const Day: FC<Props> = ({ disabled, day, status, selected, today, handleClick }) => {
  const { brandColor: color } = useContext(AccountContext);
  const brandColor = disabled ? disabledColor(color) : color;

  return (
    <div className={classNames('day', `day-${status}`, { 'day-selected': selected })} key={day}>
      {status === 'available' ? (
        <div className="day-link-container" style={{ borderColor: brandColor, backgroundColor: brandColor }}>
          {!disabled ? (
            <button className="day-link" style={{ color: brandColor }} onClick={handleClick}>
              {day}
              {today && <div className="today">&bull;</div>}
            </button>
          ) : (
            <span className="day-link" style={{ color: brandColor }}>
              {day}
              {today && <div className="today">&bull;</div>}
            </span>
          )}
        </div>
      ) : (
        <span>
          {day}
          {today && <div className="today">&bull;</div>}
        </span>
      )}
    </div>
  );
};

export default Day;
