import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';

import { only } from '../shared/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  disabled: boolean;
  loading: boolean;
  month: number;
  year: number;
  setMonthYear: (month: number, year: number) => void;
}

const NextMonthLink: FC<Props> = ({ disabled, loading, month, year, setMonthYear }) => {
  const { brandColor } = useContext(AccountContext);

  if (loading) {
    return null;
  }

  let boundHandler;

  if (month === 12) {
    // December
    boundHandler = setMonthYear.bind(null, year + 1, 1);
  } else {
    boundHandler = setMonthYear.bind(null, year, month + 1);
  }

  const style = { color: disabled ? '#ddd' : brandColor };

  return (
    <div className="month-link" style={style}>
      {!disabled ? (
        <button onClick={only(boundHandler)}>
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      ) : (
        <span>
          <FontAwesomeIcon icon="chevron-right" />
        </span>
      )}
    </div>
  );
};

export default NextMonthLink;
