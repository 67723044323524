import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AccountContext, { NULL_ACCOUNT } from '@/AccountContext';

import useFetch from '../shared/hooks/useFetch';
import { findAsync } from '../shared/utilities';

import AppointmentDetails from './AppointmentDetails';
import DateSelector from './DateSelector';
import SelectPrompt from './SelectPrompt';
import TimeSelector from './TimeSelector';
import TimeZoneDetector from './TimeZoneDetector';
import TimeZoneSelector from './TimezoneSelector';
import LanguageSelector from './LanguageSelector';

import type UtmAttributes from '@models/UtmAttributes';
import type { RailsTimezone, Timezone } from '@models/Timezone';

interface Props {
  token: string;
  resourceIds: string;
  appointmentTypeId: string;
  brandColor: string;
  allZones: RailsTimezone[];
  ticket: {
    number: string | null;
    summary: string | null;
  };
  appointmentName: string;
  resourceNames: string;
  durationMins: number;
  monthsToCheck: number;
  rescheduling: boolean;
  utm: UtmAttributes;
}

type DateAvailability = { date: string; status: string };
type Day = { year: number; month: number; day?: number };
type TimeAvailability = { start: string; end: string; available: boolean };

const generateDates = (date: Day, months: number): Day[] => {
  const dates = [date];

  for (let i = months; i > 0; --i) {
    const lastDate = dates[dates.length - 1];
    let newDay: Day;

    if (lastDate.month === 12) {
      newDay = { ...lastDate, month: 1, year: lastDate.year + 1 };
    } else {
      newDay = { ...lastDate, month: lastDate.month + 1 };
    }

    dates.push(newDay);
  }

  return dates;
};

const datesUrl = ({
  baseUrl,
  date,
  timeZone,
  token,
}: {
  baseUrl: string;
  date: Day;
  timeZone: string;
  token: string;
}): string => {
  return `${baseUrl}/dates/${date.year}/${date.month}?timezone=${encodeURIComponent(timeZone)}&token=${token}`;
};

const timesUrl = ({
  baseUrl,
  date,
  timeZone,
  token,
}: {
  baseUrl: string;
  date: Day;
  timeZone: string;
  token: string;
}): string => {
  return `${baseUrl}/times/${date.year}/${date.month}/${date.day}?timezone=${encodeURIComponent(
    timeZone
  )}&token=${token}`;
};

const AppointmentSelector: FC<Props> = ({
  token,
  resourceIds,
  appointmentTypeId,
  brandColor,
  allZones,
  ticket,
  appointmentName,
  resourceNames,
  durationMins,
  monthsToCheck,
  rescheduling,
  utm,
}) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'scheduling_ui.appointment_selector' });

  const initialData = useMemo(() => [], []);
  const today = new Date();
  const [noDates, setNoDates] = useState(monthsToCheck === 0);
  const [date, setDate] = useState<Day>({ year: today.getFullYear(), month: today.getMonth() + 1 });
  const [timeZone, setTimeZone] = useState(() => TimeZoneDetector.detect());
  const [dates, datesLoading, datesError, fetchDates] = useFetch<DateAvailability[]>(initialData);
  const [times, timesLoading, _timesError, fetchTimes] = useFetch<TimeAvailability[]>(initialData);
  const baseUrl = `/schedule/${resourceIds}/${appointmentTypeId}`;

  useEffect(() => {
    i18n.changeLanguage(i18n.language);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Mark request as viewed
    if (token) {
      fetch(`/schedule/${token}/view`, {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        method: 'POST',
      });
    }

    // Find first available month
    const datesToCheck = generateDates(date, monthsToCheck);

    (async (): Promise<void> => {
      const newDate = await findAsync(datesToCheck, date => {
        return fetchDates(datesUrl({ baseUrl, date, timeZone: timeZone.name, token: token }), 'GET', {
          throwOnError: true,
        }).then((dates: DateAvailability[]) => dates.some(d => d.status === 'available'));
      });

      setDate(newDate || datesToCheck[datesToCheck.length - 1]);
      setNoDates(!newDate);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectMonth = (year: number, month: number): void => {
    fetchDates(datesUrl({ baseUrl, date: { year, month }, timeZone: timeZone.name, token: token }), 'GET');
    setDate({ month, year, day: undefined });
  };

  const selectDay = (day: number): void => {
    fetchTimes(timesUrl({ baseUrl, date: { ...date, day }, timeZone: timeZone.name, token: token }), 'GET');
    setDate({ ...date, day });
  };

  const selectTimeZone = (timeZone: Timezone): void => {
    fetchDates(datesUrl({ baseUrl, date, timeZone: timeZone.name, token: token }), 'GET');
    if (date.day) fetchTimes(timesUrl({ baseUrl, date, timeZone: timeZone.name, token: token }), 'GET');
    setTimeZone(timeZone);
  };

  const submitUrl: () => string = () => {
    if (token) {
      return `/schedule/${token}/confirm`;
    } else {
      return `${window.location.pathname}/confirm`;
    }
  };

  const handleChangeLocale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <AccountContext.Provider value={{ ...NULL_ACCOUNT, brandColor: `#${brandColor}` }}>
      <div className="appointment-selector-v2 container">
        <div className="scheduling-select-header">
          <div className="instruction">
            {t('instruction', {
              context: `${rescheduling && 'reschedule'}`,
              appointment_name: appointmentName,
              appointment_resources: resourceNames,
            })}
          </div>
          <LanguageSelector language={i18n.language} onChangeLocale={handleChangeLocale} />
        </div>
        <AppointmentDetails
          appointmentName={appointmentName}
          durationMins={durationMins}
          resourceNames={resourceNames}
          ticketNumber={ticket.number}
          ticketSummary={ticket.summary}
        />

        {datesError ? (
          <p className="error text-center">
            There was an error when fetching dates for this appointment. Please try again.
          </p>
        ) : (
          <>
            {noDates ? (
              <strong>There are no dates available for this appointment.</strong>
            ) : (
              <>
                <div className="selection-container">
                  <DateSelector
                    loading={datesLoading || dates.length === 0}
                    disabled={timesLoading}
                    day={date?.day || 0}
                    month={date?.month || 0}
                    year={date?.year || 0}
                    dates={dates}
                    selectDay={selectDay}
                    selectMonth={selectMonth}
                  />
                  {date.day ? (
                    <TimeSelector
                      loading={timesLoading}
                      submitUrl={submitUrl()}
                      timeZone={timeZone}
                      language={i18n.language}
                      times={times}
                      date={new Date(date?.year || 0, (date?.month || 1) - 1, date.day)}
                      utm={utm}
                    />
                  ) : (
                    datesLoading || <SelectPrompt />
                  )}
                </div>
                <TimeZoneSelector timeZone={timeZone} allZones={allZones} handleTimeZoneChange={selectTimeZone} />
              </>
            )}
          </>
        )}
      </div>
    </AccountContext.Provider>
  );
};

export default AppointmentSelector;
