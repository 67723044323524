import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

const SelectPrompt: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'scheduling_ui.select_prompt' });

  return (
    <div className="times-selector">
      <div className="loading">{t('prompt')}</div>
    </div>
  );
};

export default SelectPrompt;
