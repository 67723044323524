import type { FC } from 'react';
import { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Loading from './Loading';
import TimeButton from './TimeButton';

import type { Timezone } from '@models/Timezone';
import type UtmAttributes from '@models/UtmAttributes';

interface Props {
  date: Date;
  loading: boolean;
  submitUrl: string;
  timeZone: Timezone;
  language: string;
  times: { start: string; end: string; available: boolean }[];
  utm: UtmAttributes;
}

const TimeSelector: FC<Props> = ({ date, loading, submitUrl, timeZone, language, times, utm }) => {
  const [submitting, setSubmitting] = useState(false);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'scheduling_ui.time_selector' });

  if (loading) {
    return (
      <div className="times-selector">
        <Loading />
      </div>
    );
  }

  const availableTimes = times.filter(t => t.available);

  if (availableTimes.length === 0) {
    return (
      <div className="loading">
        <div className="text-center">{t('no_available_time.notification')}</div>
        <div className="small text-center">{t('no_available_time.instruction')}</div>
      </div>
    );
  }

  const handleTimeSelect = () => {
    setSubmitting(true);
  };

  return (
    <div className="times-selector">
      <div className="date-title">
        {date.toLocaleDateString(i18n.language, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </div>
      <div className="timezone-message">
        <Trans t={t} i18nKey="available_time" values={{ timezone: timeZone.name }} />
      </div>
      {availableTimes.map(time => (
        <TimeButton
          key={`time-${time.start}`}
          datetime={time.start}
          disabled={submitting}
          submitUrl={submitUrl}
          timeZone={timeZone}
          language={language}
          utm={utm}
          onSelect={handleTimeSelect}
        />
      ))}
    </div>
  );
};

export default TimeSelector;
