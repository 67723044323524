import type { FC } from 'react';

import Week from './Week';
import WeekdayHeading from './WeekdayHeading';

type DateStatus = { date: string; status: string };
type MaybeDateStatus = DateStatus | undefined;

// Returns the number of days in the week at the start of a month
function daysInWeekBeforeMonthStart(year: number, monthIndex: number): number {
  const d = new Date(year, monthIndex, 1);
  return d.getDay();
}

// Returns the number of days in the weke after the end of the month
function daysInWeekAfterMonthEnd(year: number, monthIndex: number): number {
  const d = new Date(year, monthIndex + 1, 0);
  return 6 - d.getDay();
}

function groupByWeeks(dates: MaybeDateStatus[]): MaybeDateStatus[][] {
  const weeks: MaybeDateStatus[][] = [];

  for (let i = 0, end = dates.length / 7; i < end; ++i) {
    weeks.push(dates.slice(i * 7, (i + 1) * 7));
  }

  return weeks;
}

interface Props {
  disabled: boolean;
  selectedDay: number;
  year: number;
  month: number;
  dates: DateStatus[];
  selectDay: (day: number) => void;
}

const Month: FC<Props> = ({ disabled, selectedDay, year, month, dates: initialDates, selectDay }) => {
  const dates: MaybeDateStatus[] = ([] as MaybeDateStatus[]).concat(
    new Array<undefined>(daysInWeekBeforeMonthStart(year, month - 1)).fill(undefined),
    initialDates,
    new Array<undefined>(daysInWeekAfterMonthEnd(year, month - 1)).fill(undefined)
  );

  return (
    <>
      <div className="week">
        <WeekdayHeading dayOfWeek={0} />
        <WeekdayHeading dayOfWeek={1} />
        <WeekdayHeading dayOfWeek={2} />
        <WeekdayHeading dayOfWeek={3} />
        <WeekdayHeading dayOfWeek={4} />
        <WeekdayHeading dayOfWeek={5} />
        <WeekdayHeading dayOfWeek={6} />
      </div>
      {groupByWeeks(dates).map((week, index) => (
        <Week days={week} disabled={disabled} selectedDay={selectedDay} selectDay={selectDay} key={`week-${index}`} />
      ))}
    </>
  );
};

export default Month;
