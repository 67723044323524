import type { FC } from 'react';

import { only } from '../shared/utilities';

import BlankDay from './BlankDay';
import Day from './Day';

function splitDate(date: string): [number, number, number] {
  const components = date.split('-');

  return [parseInt(components[0], 10), parseInt(components[1], 10) - 1, parseInt(components[2], 10)];
}

function isToday(day: number, monthIndex: number, year: number): boolean {
  const today = new Date();

  return day === today.getDate() && monthIndex === today.getMonth() && year === today.getFullYear();
}

type DateStatus = { date: string; status: string };
type MaybeDateStatus = DateStatus | undefined | null;

interface Props {
  disabled: boolean;
  selectedDay: number;
  selectDay: (day: number) => void;
  days: MaybeDateStatus[];
}

const Week: FC<Props> = ({ disabled, days, selectedDay, selectDay }) => {
  return (
    <div className="week">
      {days.map((date, index) => {
        if (date) {
          const [year, monthIndex, day] = splitDate(date.date);

          return (
            <Day
              disabled={disabled}
              day={day}
              handleClick={only(selectDay.bind(null, day))}
              selected={selectedDay === day}
              status={date.status}
              today={isToday(day, monthIndex, year)}
              key={`day-${day}`}
            />
          );
        } else {
          return <BlankDay key={`blank-${index}`} />;
        }
      })}
    </div>
  );
};

export default Week;
