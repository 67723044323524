import type { FC } from 'react';
import { useState, useContext } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AccountContext from '@/AccountContext';

import type { Timezone } from '@models/Timezone';
import type UtmAttributes from '@models/UtmAttributes';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  datetime: string;
  disabled: boolean;
  submitUrl: string;
  timeZone: Timezone;
  language: string;
  utm: UtmAttributes;
  onSelect?: () => void;
}

const TimeButton: FC<Props> = ({ datetime, disabled, submitUrl, timeZone, language, utm, onSelect }) => {
  const { brandColor } = useContext(AccountContext);

  const { i18n } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const date = serializeDate(datetime);
  const time = serializeTime(datetime);
  const display = displayTime(datetime, timeZone.id, i18n.language);

  const handleClick = (event: React.MouseEvent): void => {
    if (disabled) return event.preventDefault();
    if (onSelect) onSelect();

    setClicked(true);
  };

  return (
    <div key={datetime}>
      <form action={submitUrl}>
        <input type="hidden" name="date" value={date} />
        <input type="hidden" name="time" value={time} />
        <input type="hidden" name="timezone" value={timeZone.name} />
        <input type="hidden" name="language" value={language} />
        {utm.campaign && <input type="hidden" name="utm_campaign" value={utm.campaign} />}
        {utm.content && <input type="hidden" name="utm_content" value={utm.content} />}
        {utm.medium && <input type="hidden" name="utm_medium" value={utm.medium} />}
        {utm.source && <input type="hidden" name="utm_source" value={utm.source} />}
        {utm.term && <input type="hidden" name="utm_term" value={utm.term} />}
        <div
          className="btn-container mb-1"
          style={{
            backgroundColor: brandColor,
            borderColor: brandColor,
            color: brandColor,
          }}
        >
          <button
            type="submit"
            className={classNames('btn', 'w-100', { 'btn-selected': clicked })}
            onClick={handleClick}
          >
            {clicked ? (
              <>
                <FontAwesomeIcon spin icon="circle-notch" style={{ color: '#fff' }} />
                &nbsp;
                {display}
              </>
            ) : (
              display
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

function displayTime(datetime: string, timeZone: string, language: string): string {
  const localisedTime = new Date(datetime)
    .toLocaleTimeString(language, { timeStyle: 'short', timeZone: timeZone })
    .toLowerCase();
  const is12hourFormat = localisedTime.includes('am') || localisedTime.includes('pm');
  return is12hourFormat ? localisedTime : localisedTime.replace(':', 'h');
}

function serializeDate(datetime: string): string {
  return (datetime.match(/^\d\d\d\d-\d\d-\d\d/) as string[])[0];
}

function serializeTime(datetime: string): string {
  const timeParts = datetime.split('T')[1].split(':');
  return `${timeParts[0]}-${timeParts[1]}`;
}

export default TimeButton;
