import type { FC } from 'react';
import { useState } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  text: string;
  waitingText: string;
  brandColor: string | null;
}

const WaitingButton: FC<Props> = ({ text, waitingText, brandColor }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (event: React.MouseEvent): void => {
    if (clicked) {
      return event.preventDefault();
    }

    setClicked(true);
  };

  return (
    <button
      style={{ backgroundColor: '#' + brandColor, borderColor: '#' + brandColor }}
      type="submit"
      className="btn btn-primary w-100"
      onClick={handleClick}
    >
      {clicked ? (
        <>
          <FontAwesomeIcon spin icon="circle-notch" className="me-2" />
          &nbsp;
          {waitingText}
        </>
      ) : (
        text
      )}
    </button>
  );
};

export default WaitingButton;
