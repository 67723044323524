import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Loading from './Loading';
import Month from './Month';
import NextMonthLink from './NextMonthLink';
import PrevMonthLink from './PrevMonthLink';

type DateStatus = { date: string; status: string };

interface Props {
  disabled: boolean;
  loading: boolean;
  day: number;
  month: number;
  year: number;
  selectDay: (day: number) => void;
  selectMonth: (year: number, month: number) => void;
  dates: DateStatus[];
}

const DateSelector: FC<Props> = ({ disabled, loading, dates, day, month, year, selectDay, selectMonth }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'scheduling_ui.date_selector' });

  if (loading) {
    return (
      <div className="dates-selector">
        <Loading text={t('loading')} />
      </div>
    );
  }

  return (
    <div className="dates-selector">
      <div className="month-title">
        <div className="current-month">
          {formatMonth(year, month, i18n.language)}&nbsp;{year}
        </div>
        <PrevMonthLink disabled={disabled} loading={loading} setMonthYear={selectMonth} month={month} year={year} />
        <NextMonthLink disabled={disabled} loading={loading} setMonthYear={selectMonth} month={month} year={year} />
      </div>

      <Month disabled={disabled} dates={dates} year={year} month={month} selectedDay={day} selectDay={selectDay} />
    </div>
  );
};

function formatMonth(year: number, month: number, locale: string): string {
  const date = new Date(year, month - 1, 1);

  return date.toLocaleString(locale.replace('_', '-'), { month: 'long' });
}

export default DateSelector;
