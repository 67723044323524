import { type FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AccountContext from '@/AccountContext';

import { only } from '../shared/utilities';

import Select from '@shared/ui/Select';
import type { RailsTimezone, Timezone } from '@models/Timezone';

interface Props {
  allZones: RailsTimezone[];
  timeZone: Timezone;
  handleTimeZoneChange: (timezone: Timezone) => void;
}

const TimezoneSelector: FC<Props> = ({ timeZone, allZones, handleTimeZoneChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scheduling_ui.timezone_selector' });

  const [selecting, setSelecting] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState(timeZone.name);
  const { brandColor } = useContext(AccountContext);

  const showForm = only(() => {
    setSelecting(true);
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();
    setTimeZoneValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (timeZone.name !== timeZoneValue) {
      const newTimeZone = allZones.find(z => z.value === timeZoneValue)!;
      handleTimeZoneChange({ id: newTimeZone.id, name: newTimeZone.value });
    }

    setSelecting(false);
  };

  if (selecting) {
    return (
      <form className="timezone-selector" method="GET" onSubmit={handleSubmit}>
        <Select
          name="timezone"
          value={timeZoneValue}
          className="w-50"
          options={allZones.map(z => ({ value: z.value, name: z.display }))}
          onChange={handleChange}
        />
        <input
          style={{ float: 'right', backgroundColor: brandColor, color: '#fff' }}
          type="submit"
          className="btn"
          value={t('change')}
        />
      </form>
    );
  } else {
    return (
      <div className="timezone-selector">
        {t('indicator', { timezone: timeZone.name })}
        <button style={{ float: 'right', color: brandColor }} onClick={showForm}>
          {t('change')}
        </button>
      </div>
    );
  }
};

export default TimezoneSelector;
