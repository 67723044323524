import { useCallback, useRef, useState } from 'react';

import { ensureError } from '@shared/utilities';

type FetchFn<T> = (
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  options?: { body?: Record<string, any>; credentials?: 'same-origin' | 'include'; throwOnError?: boolean }
) => Promise<T>;

type FetchState<T> = [T, boolean, string | null, FetchFn<T>];

export default function useFetch<T>(initialData: T): FetchState<T> {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const controller = useRef<AbortController | null>(null);

  const fetchFn = useCallback(
    (
      url: string,
      method = 'GET',
      options = { body: undefined, credentials: undefined, throwOnError: false }
    ): Promise<T> => {
      controller.current?.abort();
      controller.current = new AbortController();

      setError(null);
      setData(initialData);
      setLoading(true);

      const params: Record<string, any> = {
        credentials: options?.credentials || 'same-origin',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        method: method,
        signal: controller.current.signal,
      };
      if (options.body) params.body = JSON.stringify(options.body);

      return fetch(url, params)
        .then(resp => resp.json())
        .then(json => {
          if (json.error) throw new Error(json.error);

          setData(json);
          setLoading(false);
          return json;
        })
        .catch(error => {
          if (controller.current?.signal.aborted) return;

          setError(error.message || error.toString());
          setLoading(false);

          if (options.throwOnError) throw ensureError(error);
        });
    },
    [initialData]
  );

  return [data, loading, error, fetchFn];
}
