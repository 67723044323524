import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import useDisplayDuration from '@shared/hooks/useDisplayDuration';

interface Props {
  ticketNumber: string | null;
  ticketSummary: string | null;
  appointmentName: string;
  resourceNames: string;
  durationMins: number;
}

function EntitySummary(props: { summary: string }): JSX.Element {
  return <>&nbsp;&mdash;&nbsp;{props.summary}</>;
}

const AppointmentDetails: FC<Props> = ({
  appointmentName,
  durationMins,
  resourceNames,
  ticketNumber,
  ticketSummary,
}) => {
  const displayDuration = useDisplayDuration();
  const { t } = useTranslation('translation', { keyPrefix: 'scheduling_ui.appointment_details' });

  return (
    <div className="appointment-details">
      {ticketNumber && (
        <div className="appointment-detail">
          <strong>{t('ticket')}:</strong>
          &nbsp;
          {ticketNumber}
          {ticketSummary && <EntitySummary summary={ticketSummary} />}
        </div>
      )}
      <div className="appointment-detail">
        <strong>{t('appointment')}:</strong>
        &nbsp;
        {appointmentName}
        &nbsp; ({displayDuration(durationMins)})
      </div>
      <div className="appointment-detail">
        <strong>{t('with')}:</strong>
        &nbsp;
        {resourceNames}
      </div>
    </div>
  );
};

export default AppointmentDetails;
