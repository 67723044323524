import 'regenerator-runtime/runtime';
import ReactRailsUJS from 'react_ujs';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import '../utils/i18n';
import { client } from '../shared/apollo';

// Support component names relative to this directory:
import * as Components from '../scheduling_ui/**/[[:alnum:]]+.tsx';
const components = Components.filenames.reduce((manifest, fileName, i) => {
  const name = fileName
    .replace('../scheduling_ui/', '')
    .replace(/\.tsx?$/, '')
    .replace(/\/index$/, '');
  manifest[name] = Components.default[i].default;
  return manifest;
}, {});
ReactRailsUJS.getConstructor = className => components[className];

window.apolloClient = client;
