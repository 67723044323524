import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  // dayOfWeek is 0-6, as per JS standard numbering
  dayOfWeek: number;
}

const WeekdayHeading: FC<Props> = ({ dayOfWeek }) => {
  const { i18n } = useTranslation();
  const date = new Date(`2020-03-0${dayOfWeek + 1}T00:00:00+00:00`);

  return (
    <div className="day">
      <strong>{date.toLocaleDateString(i18n.language, { weekday: 'short', timeZone: 'UTC' })}</strong>
    </div>
  );
};

export default WeekdayHeading;
