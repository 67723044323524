import type { FC } from 'react';

import FontAwesomeIcon from './FontAwesomeIcon';

interface Props {
  text?: string;
}

const Loading: FC<Props> = ({ text }) => {
  return (
    <div className="loading">
      <div>
        <FontAwesomeIcon spin icon="spinner" />
      </div>
      {text && <div className="loading-text">{text}</div>}
    </div>
  );
};

export default Loading;
