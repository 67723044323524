import type { FC } from 'react';

import FontAwesomeIcon from './FontAwesomeIcon';

import { LANGUAGES } from '@shared/constants';
import Select from '@shared/ui/Select';

interface Props {
  language: string;
  onChangeLocale: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const LanguageSelector: FC<Props> = ({ language, onChangeLocale }) => {
  return (
    <div className="language-selector">
      <FontAwesomeIcon style={{ cursor: 'pointer', marginRight: '5px' }} icon="earth-americas" />
      <Select
        className="w-100"
        name="language"
        value={language}
        options={LANGUAGES.map(l => ({ value: l.code, name: l.name }))}
        onChange={onChangeLocale}
      />
    </div>
  );
};

export default LanguageSelector;
